"use client";

import { HoverBorderGradient } from "../cta/hover-border-gradient";

export function MainButton() {
  return (

    <a href="/kontakta_oss">
      <HoverBorderGradient
        containerClassName="rounded-full"
        as="button"
        className=" bg-blue-600 text-white  flex items-center justify-center text-xl space-x-2 font-medium h-14 min-w-52"
      >
        <span>Kontakta oss</span>
      </HoverBorderGradient>
    </a>

  );
}

